import { Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from '@angular/core';
import {
  MobilePackageDetails,
  MobilePackageDisplayType,
} from '../../shared/components/large-mobile-package/large-mobile-package.interfaces';
import { Router } from '@angular/router';
import { MobileServicesService } from '../mobile-services/mobile-services.service';
import { SelectedPhonePackageService } from '../devices/selected-phone-package.service';
import { ScrollService } from '../../shared/services/scroll-service/scroll.service';
import Swiper, { Autoplay, Navigation, Pagination, SwiperOptions } from 'swiper';
import { CarouselElements } from './home-carousel.data';
import { isPlatformBrowser } from '@angular/common';
import { IRedirectPath, ISwiperResolutionData } from '../../shared/interfaces/swiper.interfaces';

Swiper.use([Pagination, Navigation, Autoplay]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  packages: MobilePackageDetails[] = [
    {
      newPackageDisplayMode: true,
      size: '50 GB',
      price: 6890,
      details: '',
      extraDetail: 'Korlátlan belföldi beszélgetés',
      crmIdentifier: '110083834',
      loyaltyMonth: 10,
      view: false,
      isBarlow: true,
      extra50gb: false,
      packageDisplayType: MobilePackageDisplayType.RED_BASED_WITH_WHITE,
      pillConfiguration: {
        row1: {
          text: 'Korlátlan',
          color: '#fff',
        },
        row2: {
          text: '50 GB',
          color: '#fff',
        },
        sccClass: 'two',
      },
      customIcons: {
        firstIcon: 'assets/images/akcio_arga_feher_felirat_szeles.svg',
      },
    },
    {
      newPackageDisplayMode: true,
      size: '100 GB',
      price: 7890,
      details: '',
      extraDetail: 'Korlátlan belföldi beszélgetés',
      crmIdentifier: '110083835',
      loyaltyMonth: 10,
      view: false,
      isBarlow: true,
      packageDisplayType: MobilePackageDisplayType.RED_BASED_WITH_WHITE,
      pillConfiguration: {
        row1: {
          text: 'Korlátlan',
          color: '#fff',
        },
        row2: {
          text: '100 GB',
          color: '#fff',
        },
        sccClass: 'two',
      },
      customIcons: {
        firstIcon: 'assets/images/akcio_arga_feher_felirat_szeles.svg',
      },
    },
    {
      newPackageDisplayMode: true,
      custom: true,
      size: 'SAJÁT CSOMAGOT',
      price: 0,
      priceText: 'VÁLASZTOK',
      details: '',
      view: true,
      loyaltyMonth: 0,
      packageDisplayType: MobilePackageDisplayType.WHITE_BASE_WITH_ORANGE_NO_BACKGROUND,
      hideIcons: true,
    },
  ];

  // https://swiper6.vercel.app/swiper-api

  swiperConfig: SwiperOptions = {
    spaceBetween: 0,
    navigation: true,
    autoHeight: true,
    autoplay: {
      delay: 7000,
      disableOnInteraction: false,
    },
    pagination: {
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '"></span>';
      },
    },
    loop: true,
    lazy: true,
  };

  carouselElements = CarouselElements;
  innerWidth: number;

  constructor(
    private router: Router,

    private mobileServicesService: MobileServicesService,
    private selectedPhonePackageService: SelectedPhonePackageService,
    private scrollService: ScrollService,
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private platformId: object
  ) {}
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.innerWidth = window.innerWidth;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.innerWidth = (event.target as Window).innerWidth;
  }

  viewPackage(packageItem: MobilePackageDetails): void {
    if (packageItem.crmIdentifier && !packageItem.view) {
      this.mobileServicesService.order(packageItem);
    } else {
      this.router.navigate(['mobil-szolgaltatas'], { relativeTo: null });
    }
  }

  navigateAndScrollTo(navigateTo: string, scrollTo: string): void {
    this.scrollService.navigateAndScrollToElement(navigateTo, scrollTo);
  }

  getImage(index: number): ISwiperResolutionData {
    if (this.innerWidth < 576) {
      return this.carouselElements[index].small;
    }
    if (this.innerWidth < 768) {
      return this.carouselElements[index].smallMedium;
    }
    if (this.innerWidth < 960) {
      return this.carouselElements[index].medium;
    }
    if (this.innerWidth < 1200) {
      return this.carouselElements[index].mediumLarge;
    }
    return this.carouselElements[index].large;
  }

  getBackgroundColor(image: string): string {
    return `url(${image})`;
  }

  onClickSwiperItem(redirectPath: IRedirectPath): void {
    this.router.navigate([redirectPath.url], { relativeTo: null, queryParams: redirectPath.queryParams ?? {} });
  }
}
